import React from "react";

import Block from "./Block";
import Field from "./Field";

import styled from "styled-components";

function Sector({ content, path, page, update, onEnabledCam, enabledCam }) {
  let pageIdx = 0;

  if (path === undefined) path = [];

  return (
    <>
      {content.map((element, idx) => {
        let isElementFieldCorrect = !!element.field;
        try {
          isElementFieldCorrect = isNaN(parseInt(element.field));
        } catch (_) {}

        return (
          <div key={idx} style={{ marginBottom: "25px" }}>
            {/* Páginas só podem estar presentes no primeiro nível // TODO: */}
            {element.type === "page" && pageIdx++ === page && (
              <Block update={update} page={pageIdx - 1} /* <- Melhorar */ form={element} path={path} type="page" onEnabledCam={onEnabledCam} enabledCam={enabledCam}/>
            )}

            {element.type === "block" && element.field && (
              <Block update={update} page={page} form={element} path={path} type="block" parent={content} onEnabledCam={onEnabledCam} enabledCam={enabledCam}/>
            )}

            {element.type === "block" && !element.field && <BlockWithoutFieldError element={element} />}

            {element.type !== "block" && element.type !== "page" && (
              <>
                {isElementFieldCorrect && (
                  <Field update={update} page={page} type={element.type} form={element} path={path} onEnabledCam={onEnabledCam} enabledCam={enabledCam} />
                )}
                {!isElementFieldCorrect && <FieldWithoutFieldError element={element} />}
              </>
            )}
          </div>
        );
      })}
    </>
  );
}

function BlockWithoutFieldError({ element }) {
  return (
    <StyledError>
      <strong>Block must have a specific field!</strong>
      <div>{JSON.stringify(element)}</div>
    </StyledError>
  );
}

function FieldWithoutFieldError({ element }) {
  return (
    <StyledError>
      <strong>Field must have a specific STRING field!</strong>
      <div>{JSON.stringify(element)}</div>
    </StyledError>
  );
}

const StyledError = styled.div`
  strong {
    color: red;
  }
  > div {
    font-size: 0.8em;
  }
`;

export default Sector;
