import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useUser } from "./useUser";
import { useHistory, Link } from "react-router-dom";

import logo from "../images/logo.png";
import logoOrigensBrasil from '../images/logoorigensbrasil.png'
import { Typography } from "@material-ui/core";

import AppForm from "./views/AppForm";

export default () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userData, { login }] = useUser();
  const history = useHistory();

  useEffect(() => {
    if (userData.logged) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [userData.logged]);

  useEffect(() => {
    setLoginError();
  }, [email, password]);

  const [loginError, setLoginError] = useState();

  return (
    <>
      {/* <AppForm> */}
        {process.env.REACT_APP_SITE_NAME === "Avina Collect" && (
          <LogoContainer>
            <img src={logo} />
          </LogoContainer>
        )}
        {process.env.REACT_APP_SITE_NAME === "Coletor Origens Brasil" && (
          <LogoContainerOrigens>
            <img src={logoOrigensBrasil} />
          </LogoContainerOrigens>
        )}
        <Typography align="center" variant="h5" color="textPrimary">
          Login
        </Typography>
        <LoginContainer>
          
          <form noValidate autoComplete="off">
            <div>
              <TextField label="E-mail" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <TextField
                label="Senha"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  login({
                    email,
                    password,
                    onSuccess: () => {
                      history.go("/");
                    },
                    onFailure: (erro) => {
                      setLoginError(erro.error);
                    },
                  })
                }
              >
                Entrar
              </Button>

              <Typography align="center" variant="body2" color="textPrimary" style={{marginTop: 10}}>
                <Link style={{textDecoration: 'none'}} to="/esqueci-minha-senha">
                  Esqueceu sua senha?
                </Link>
              </Typography>
              <FormHelperText error> {loginError}</FormHelperText>
            </div>
          </form>
        </LoginContainer> 
      {/* </AppForm> */}
      
    </>
  );
};
const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 0px;

  & form {
    margin: 0 auto;
  }

  & div {
    padding: 2px;
  }

  button {
    width: 100%;
  }
`;
const LogoContainer = styled.div`
  text-align: center;
  margin: 70px 0 0 0;
`;

const LogoContainerOrigens = styled.div`
  text-align: center;
  margin: 70px 0 0 0;

  img {
    width: 150px;
    height: 150px;
  }
`;
