import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PostAddIcon from "@material-ui/icons/PostAdd";

import Tooltip from '@material-ui/core/Tooltip';


import styled from "styled-components";

import { useUser } from "../auth/useUser";

export default () => {
  const [{ forms, formsByClient }, { fetchForms }] = useUser();
  const history = useHistory();
  /* let groupedForms = {}; */

  useEffect(() => {
    fetchForms();
  }, []);

  const navigateToForm = (id) => {
    history.push(`/coletar/${id}`);
  };

  const navigateToView = (id) => {
    history.push(`/ver/${id}`);
  };

  return (
    <Container elevation={0}>
      {!forms && <div>Carregando seus formulários...</div>}
      {forms && forms.length === 0 && <div>Nenhum formulário cadastrado!</div>}
      {forms && forms.length > 0 && (
        <>
          <List dense={false} style={{ paddingBottom: 0 }}>
            {Object.keys(formsByClient).map((key, idx) => {
              // console.log(formsByClient[key]);
              return (
                <Project key={idx}>
                  <Typography className="p-title">{key}</Typography>
                  {formsByClient[key].map(({ id, title, imageForm }) => (
                    <ListItem key={id}>
                      {imageForm === null && <ListItemText primary={title} />}
                      {imageForm !== undefined && (
                        <ListItemAvatar onClick={() => navigateToView(id)}>
                          <img style={{ width: "150px" }} src={imageForm} />
                        </ListItemAvatar>
                      )}
                      <ListItemSecondaryAction>
                        <Tooltip title="Adicionar">
                          <IconButton edge="end" onClick={() => navigateToForm(id)}>
                            <PostAddIcon />
                          </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Visualizar">
                          <IconButton edge="end" onClick={() => navigateToView(id)}>
                            <ListAltIcon />
                          </IconButton>
                        </Tooltip>
                        
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </Project>
              );
            })}
          </List>
        </>
      )}
      <Legenda>
        <div>
          <div>
            <PostAddIcon />
          </div>
          <div className="l-text">Novo questionário</div>
        </div>
        <div>
          <div>
            <ListAltIcon />
          </div>
          <div className="l-text">Questionários aplicados</div>
        </div>
      </Legenda>
    </Container>
  );
};

/* Styles */
const Project = styled.div`
  margin-top: 5px;
  margin-top: 5px;
  margin-bottom: 10px;

  li {
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }

  .p-title {
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: solid 1px #ccc;
  }

  li:not(:last-child) {
    border-bottom: solid 1px #ccc;
  }
`;

const Legenda = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > div {
    display: flex;
  }

  svg {
    width: 0.6em;
  }

  .l-text {
    font-size: 0.85em;
    margin-top: 5px;
    margin-left: 3px;
  }
`;
