import { useEffect, useState } from "react";

const createProfile = (initialValue) => {
  let _updaters = [];
  let _value = initialValue;

  const useUser = () => {
    const [value, update] = useState(_value);

    useEffect(() => {
      _updaters.push(update);
      return () => (_updaters = _updaters.filter((el) => el !== update));
    }, []);

    return value;
  };

  const updateUser = (updateValue) => {
    _value = typeof updateValue === "function" ? updateValue(_value) : updateValue;
    _updaters.forEach((cb) => cb(_value));
  };

  return [useUser, updateUser];
};

var initialState;
var token;
var user;

if (localStorage.getItem(`${process.env.REACT_APP_NAME}-token`)) {
  token = localStorage.getItem(`${process.env.REACT_APP_NAME}-token`);
  user = JSON.parse(localStorage.getItem("user"));
  initialState = { token, user, logged: true };
} else {
  initialState = { logged: false };
}

const [useUserData, updateUserData] = createProfile(initialState);

export { useUserData, updateUserData };
