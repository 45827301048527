import React, { useState, useEffect } from "react";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import { CssBaseline } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import styled from "styled-components";

import Router from "./Router";
import ThemeProvider from "./ThemeProvider";

let prompt = null;

function App() {
  const [showInstallBar, setShowInstallBar] = useState(false);

  // listen "install" event
  useEffect(() => {
    function ready(e) {
      e.preventDefault();
      prompt = e;
      setShowInstallBar(true);
    }

    function installed() {
      setShowInstallBar(false);
    }

    window.addEventListener("beforeinstallprompt", ready);
    window.addEventListener("appinstalled", installed);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
      window.removeEventListener("appinstalled", installed);
    };
  }, []);

  const handleInstall = () => {
    if (prompt) prompt.prompt();
  };

  return (
    <ThemeProvider>
      <CssBaseline />
      <div>
        {showInstallBar && (
          <InstallBar>
            <Button onClick={handleInstall} variant="contained" startIcon={<AddToHomeScreenIcon />}>
              instalar o {process.env.REACT_APP_SITE_NAME}
            </Button>
          </InstallBar>
        )}
        <Router />
      </div>
    </ThemeProvider>
  );
}

const InstallBar = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 20px;
  background-color: #666;
  color: #fff;
`;

export default App;
