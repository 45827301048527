import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import UploadIcon from "@material-ui/icons/CloudUpload";

import localforage from "localforage";
import axios from "axios";

import { getDatabaseName, getAllDataFromForm, removeReady } from "../../utils/helpers";
import { useUser } from "../../auth/useUser";

export default function SendData({ isOnline, onSent, onError }) {
  const [sending, setSending] = useState(false);
  const [sendingMessage, setSendingMessage] = useState("");
  const [{ forms, ...userData }] = useUser();

  const handleSend = async () => {
    setSending(true);

    try {
      for (let idx in forms) {
        const form = forms[idx];
        const databaseName = getDatabaseName(userData.user.id, form.id);

        const store = localforage.createInstance({
          name: databaseName,
        });

        const length = await store.length();

        if (length > 0) {
          setSendingMessage(`Enviando ${form.title}...`);

          onSent(); // vai para a home

          // Somente os itens com ready -> true
          const allFormsData = await getAllDataFromForm(store, true);

          console.log(allFormsData);

          for (let row of allFormsData) {
            
            // Send to API

            const { status, data } = await axios.post(`${process.env.REACT_APP_SERVER}collect-service/save-one`, {
              formId: form.id,
              data: row,
            });

            // console.log({ data });
            await store.setItem(row.key, { ...row, removedTS: Date.now(), sentID: data.id });

            if (status !== 200) {
              // interrompe
              // TODO!
            }
          }

        }
      }

      setSendingMessage("Dados enviados!");
    } catch (err) {
      onError(err);
      setSendingMessage("Falha no envio, verifique sua conexão.");
      throw err;
    } finally {
      setTimeout(() => {
        setSending(false);
        setSendingMessage("");
      }, 3000);
    }
  };

  return (
    <ListItem button onClick={handleSend} disabled={!isOnline || sending || !forms}>
      <ListItemIcon>
        <UploadIcon />
      </ListItemIcon>
      <ListItemText primary={sendingMessage || "Enviar Coletas"} />
    </ListItem>
  );
}
