import { useState, useEffect } from "react";

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleStatusChange() {
      setIsOnline(navigator.onLine);
    }
    if (window) {
      window.addEventListener("online", handleStatusChange);
      window.addEventListener("offline", handleStatusChange);
    }
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  });

  return isOnline;
}

export { useNetworkStatus };
