import React, { useState, useCallback } from "react";

import { FieldsContext /* , BlurContext */, DataContext, InvalidContext, ListsContext } from "./contexts";

import Sector from "./Sector";

import Box from "@material-ui/core/Box";

import Paginator from "./Paginator";

import { setDataByPath } from "./utils";

import styled from "styled-components";

function FormBuilder({ form, onChange, data, saving, updated, invalid, invalidPages, onPageChange }) {
  const [currentPage, setCurrentPage] = useState(0);

  const [update, setUpdate] = useState(new Date());

  const [enabledCam, _enabledCam] = useState(null);

  const handleCamEnable = (uuid) => {
    _enabledCam(uuid);
  }

  const handleChange = (path, value) => {
    setUpdate(new Date());
    onChange(setDataByPath(data, path, value));
  };

  const handlePaginatorChanges = (page) => {
    setCurrentPage(page);
    onPageChange();
  };

  const { content, lists } = form;

  const totalPages = content.filter((element) => element.type === "page").length;

  return (
    <>
      <PersistenceStatus>
        <span> - </span>
        {saving !== null && (
          <>
            {saving && <span>Gravando...</span>}
            {!saving && updated && <span>Não gravado</span>}
            {!saving && !updated && <span>Gravado</span>}
          </>
        )}
        {saving === null && (
          <>
            {updated && <span>Não gravado</span>}
            {!updated && <span>Sem alterações</span>}
          </>
        )}
        <span> - </span>
      </PersistenceStatus>
      {totalPages > 1 && (
        <Paginator
          currentPage={currentPage}
          totalPages={totalPages}
          invalidPages={invalidPages}
          onChange={handlePaginatorChanges}
        />
      )}
      <Box mx={2} mt={4} mb={6}>
        <form noValidate autoComplete="off">
          <DataContext.Provider value={data}>
            <FieldsContext.Provider value={handleChange}>
              {/* <BlurContext.Provider value={handleBlur}> */}
              <InvalidContext.Provider value={invalid}>
                <ListsContext.Provider value={form.lists}>
                  <Sector update={update} content={content} page={currentPage} onEnabledCam={handleCamEnable} enabledCam={enabledCam} />
                </ListsContext.Provider>
              </InvalidContext.Provider>
              {/*  </BlurContext.Provider> */}
            </FieldsContext.Provider>
          </DataContext.Provider>
        </form>
      </Box>
      {totalPages > 1 && (
        <Paginator
          currentPage={currentPage}
          totalPages={totalPages}
          invalidPages={invalidPages}
          onChange={handlePaginatorChanges}
        />
      )}
    </>
  );
}

const PersistenceStatus = styled.div`
  width: 100%;
  margin: 10px 0;
  text-align: center;
  color: #bbb;
`;

export default FormBuilder;
