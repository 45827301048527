import getValue from 'get-value';

import availability from "./builder/availability";
import validations from "./builder/validations";
import iterations from "./builder/iterations";


const analyseTree = (form, data) => {
  /* Recursive function */
  function step({ branch, dataBranch, wholeData, path, branchCleanData, validation, page, ident, debug }) {
    branch.map((element) => {
      debug && console.log(`${ident}---------------------------------`);
      debug && console.log(`${ident}--- Analysing element: ${element.type} ${element.field ? element.field : ""}`);
      debug && console.log(`${ident}---------------------------------`);

      // base data for next recursion
      let baseData = {
        wholeData,
        branch: element.children,
        branchCleanData,
        validation,
        page,
        path,
        ident: `${ident}+`,
        debug,
      };

      if (element.type === "page")
        step({
          ...baseData,
          dataBranch: dataBranch,
          page: page++,
          ident: "",
        });
      else {
        if (element.type === "block") {
          debug && console.log(`${ident}${element.field}(${element.iteration ? "ITERATIVE BLOCK" : "BLOCK"})`);

          // available, se presente, define se a recursão se aprofunda
          let isAvaliable = true;
          if (element.available) {
            let aType = element.available.type ? element.available.type : "and";
            if (availability[aType]) {
              isAvaliable = availability[aType](element.available.conditions, wholeData, path);
            }
          }
          if (!isAvaliable) {
            return;
          }

          if (element.iteration /*  && dataBranch[element.field] */) {
            let total = 1;

            // iteration
            if (iterations[element.iteration.type]) {
              const IterationStrategy = iterations[element.iteration.type];
              const isFreeIteration = iterations.getIterative(element.iteration.type);

              let totalField;
              if (isFreeIteration) {
                totalField = [...path, `__${element.field}`].join(".");

                const wtf = getValue(wholeData, totalField);
                if (wtf) {
                  total = wtf;

                  branchCleanData[`__${element.field}`] = total;
                }

              } else {

                const { items: iterationItems } = IterationStrategy({
                  field: element.field,
                  path,
                  strategy: element.iteration,
                  state: wholeData,
                  parent: branch,
                });
                total = iterationItems.length;
              }
            }

            // Entra em cleanData (array)
            branchCleanData[element.field] = [];

            // esta quantidade tem que ser determinada pela iteration (dependent) ou pela campo "totalField" (free - isFreeIteration)!
            for (let idx = 0; idx < total; idx++) {
              // Entra em cleanData (object)
              branchCleanData[element.field][idx] = {};
              step({
                ...baseData,
                dataBranch: dataBranch && dataBranch[element.field] ? dataBranch[element.field][idx] : null,
                path: [...path, element.field, idx],
                branchCleanData: branchCleanData[element.field][idx],
              });
            }
          } else {
            // Entra em cleanData (object)
            branchCleanData[element.field] = {};

            // no iteration
            step({
              ...baseData,
              dataBranch: dataBranch && dataBranch[element.field] ? dataBranch[element.field] : null,
              path: [...path, element.field],
              branchCleanData: branchCleanData[element.field],
            });
          }
        } else {
          let isAvaliable = true;
          if (element.available) {
            let aType = element.available.type ? element.available.type : "and";
            if (availability[aType]) {
              isAvaliable = availability[aType](element.available.conditions, wholeData, path);
            }
          }
          if (!isAvaliable) {
            return;
          }

          if (dataBranch === undefined || dataBranch === null) {
            dataBranch = { [element.field]: null }; // pode ser null ou depende de campo? - ESTUDO!
          }

          // VALUE
          let value = "empty";
          if (dataBranch[element.field] !== undefined && dataBranch[element.field] !== null)
            value = dataBranch[element.field];

          if (element.validate && element.validate.type && validations[element.validate.type]) {
            const [isValid, errors] = validations[element.validate.type](
              element.validate.conditions,
              element.field,
              dataBranch,
              []
            );
            if (!isValid) {
              const flatName = [...path, element.field].join(".");
              validation.valid = false;
              validation.errors[flatName] = errors;
              if (!validation.invalidPages.includes(page)) validation.invalidPages.push(page);
              console.error(`${flatName}(${element.type}): ${value}`, errors);
            }
          }

          // se valor está presente, ele entra na cleanData
          if (value !== "empty") branchCleanData[element.field] = value;
        }
      }
    });
  }

  /* Call recursive function */
  const DEBUG = true;
  let cleanData = {};
  let validation = { valid: true, errors: {}, invalidPages: [] };
  step({
    branch: form,
    wholeData: data ? data : {},
    dataBranch: data ? data : {},
    branchCleanData: cleanData,
    validation,
    path: [],
    page: 0,
    ident: "",
    debug: DEBUG,
  });

  DEBUG && console.log("validation", validation);

  return { cleanData, validation };
};

export default analyseTree;
