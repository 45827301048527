import { getDataByPath, absTarget } from "../utils";

const Strategies = {
  "field-value": ({ target, value }, state, path) => {
    const reference = getDataByPath(state, absTarget(path, target));

    if (reference === null || reference === undefined || (!Array.isArray(reference) && reference !== value) || (Array.isArray(reference) && !reference.includes(value)) )  return false;
    return true;
  },
  "field-array-includes": ({ target, value }, state, path) => {
    const reference = getDataByPath(state, absTarget(path, target));

    if (reference === null || reference === undefined || !reference.includes(value)) return false;
    return true;
  },
  gt: ({ target, value }, state, path) => {
    const reference = getDataByPath(state, absTarget(path, target));

    if (!reference || reference <= value) return false;
    return true;
  },
  boolean: ({ target, value }, state, path) => {
    const reference = state[Strategies.absTarget(path, target)];

    if (!reference || reference !== value) return false;
    return true;
  },
  /* Special condition aggregators */
  and: (conditions, state, path) => {
    const conditionList = [...conditions];

    while (conditionList.length) {
      const condition = conditionList.pop();
      if (Strategies[condition.type] && !Strategies[condition.type](condition, state, path)) return false;
    }
    return true;
  },
  or: (conditions, state, path) => {
    const conditionList = [...conditions];

    while (conditionList.length) {
      const condition = conditionList.pop();
      if (Strategies[condition.type] && Strategies[condition.type](condition, state, path)) return true;
    }
    return false;
  },
};

export default Strategies;
