import axios from "axios";
import { useUserData, updateUserData } from "../utils/createAuthState";

const useUser = () => {
  const userData = useUserData();

  const login = async ({ email, password, onSuccess, onFailure, initState = true }) => {
    var forms;
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER}id-service/login`, {
        email,
        password,
      });

      const { user, token } = response.data;

      localStorage.setItem(`${process.env.REACT_APP_NAME}-token`, token);
      localStorage.setItem("user", JSON.stringify(user));
      if (initState) {
        forms = await fetchForms();
      }

      updateUserData({ ...userData, token, user, forms });

      onSuccess &&
        onSuccess({
          user,
          token,
        });
    } catch (e) {
      onFailure &&
        onFailure({
          error: (e.response && e.response.data) || e.message,
        });
    }
  };

  const logout = async (callback) => {
    localStorage.removeItem(`${process.env.REACT_APP_NAME}-token`);
    localStorage.removeItem("user");
    await updateUserData({ logged: false, user: undefined, token: undefined });

    if (callback) {
      callback();
    }
  };

  const fetchForms = async () => {
    if (userData.logged) {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}collect-service/forms/${userData.user.id}`); // id just for offline caching

      const { forms } = response.data;

      const formsByClient = groupBy(forms, "clientName");

      const clients = Object.keys(formsByClient);

      updateUserData({ ...userData, forms, formsByClient, clients });

      return forms;
    }
    return null;
  };

  return [userData, { login, logout, fetchForms }];
};

export { useUser };

var groupBy = function (data, key) {
  return data.reduce(function (storage, item) {
    var group = item[key];
    storage[group] = storage[group] || [];
    storage[group].push(item);
    return storage;
  }, {});
};
