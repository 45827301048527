import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import axios from "axios";

import { BrowserRouter as Router } from "react-router-dom";

axios.interceptors.request.use((config) => {
  if (localStorage.getItem(`${process.env.REACT_APP_NAME}-token`))
    config.headers["x-access-token"] = localStorage.getItem(`${process.env.REACT_APP_NAME}-token`);

  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
