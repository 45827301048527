const Strategies = {
  required: (_, name, state) => {
    const reference = state[name];

    return (
      reference !== null &&
      reference !== undefined &&
      reference !== -1 &&
      (typeof reference !== "string" || reference.length > 0)
    );
  },

  "text-only": (_, name, state) => {
    const reference = state[name];

    return !/\d/.test(reference);
  },

  gt: ({ value }, name, state) => {
    const reference = state[name];

    if (isNaN(reference)) return true;

    return reference > value;
  },

  between: ({ startVal, endVal }, name, state) => {
    const reference = state[name];

    if (isNaN(reference)) return true;

    return reference >= startVal && reference <= endVal;
  },

  /** multiple-select minimum  */
  "min-choices": (conditions, name, state) => {
    const reference = state[name];

    return reference !== undefined && state[name].length >= conditions.value;
  },

  /* Special condition aggregators */
  and: (conditions, name, state, path) => {
    const conditionList = [...conditions];
    let valid = true;
    let errors = [];

    while (conditionList.length) {
      const condition = conditionList.pop();
      if (Strategies[condition.type] && !Strategies[condition.type](condition, name, state, path)) {
        valid = false;
        errors.push(condition.type);
      }
    }
    return [valid, errors];
  },
  or: (conditions, name, state, path) => {
    const conditionList = [...conditions];
    let valid = false;
    let errors = [];

    while (conditionList.length) {
      const condition = conditionList.pop();
      if (Strategies[condition.type] && Strategies[condition.type](condition, name, state, path)) valid = true;
      else {
        errors.push(condition.type);
      }
    }
    return [valid, errors];
  },

  /* Auxiliary functions and data */
  absTarget: (path, target) => {
    if (target.includes("$")) return target.replace("$", `${path.join(".")}.`);
    return target;
  },

  /* Short */
  getShort: (name) => {
    switch (name) {
      case "required":
        return { conditions: [{ type: "required", errorMessage: "Você deve preencher este campo!" }] };
      default:
        return null;
    }
  },
};

export default Strategies;
