import React from "react";

import styled from "styled-components";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import classnames from "classnames";

function Paginator({ currentPage, totalPages, invalidPages, onChange }) {
  let items = [];
  for (let idx = 0; idx < totalPages; idx++) {
    items.push(
      <PageButton
        className={classnames({ current: idx === currentPage, hasError: invalidPages.includes(idx) })}
        onClick={() => onChange(idx)}
        disabled={currentPage === idx}
        key={idx}
      >
        {idx + 1}
      </PageButton>
    );
  }

  return (
    <PaginatorContainer>
      <ButtonGroup fullWidth={true} color="primary" aria-label="outlined primary button group">
        <Button
          variant="contained"
          color="primary"
          onClick={() => onChange(currentPage - 1)}
          disabled={currentPage === 0}
          startIcon={<ChevronLeftIcon />}
        />
        {items}
        <Button
          variant="contained"
          color="primary"
          onClick={() => onChange(currentPage + 1)}
          disabled={currentPage >= totalPages - 1}
          startIcon={<ChevronRightIcon />}
        />
      </ButtonGroup>
    </PaginatorContainer>
  );
}
const PaginatorContainer = styled.div`
  margin: 10px 30px;
`;
const PageButton = styled(Button)`
  &.hasError {
    border-bottom: solid 2px #f00;
  }
  &.current {
    background-color: #f3f3f3;
  }
`;

export default Paginator;
