import validations from "./validations";

export const getErrorMessage = ({ validate }, error) => {
  // TODO: if short?

  if (typeof validate === "string") {
    // Short?
    validate = validations.getShort(validate);
  }

  const [{ errorMessage }] = validate.conditions.filter(({ type }) => type === error);

  return errorMessage;
};

export const getDataByPath = (data, path) => {
  if (!data || !path) return null;

  let branch = data;
  let pathArray = path.split(".");
  for (let idx in pathArray) {
    const key = typeof pathArray[idx] === "string" ? pathArray[idx] : parseInt(pathArray[idx]);
    if ([undefined, null].includes(branch[key])) {
      branch = null;
      break;
    }
    branch = branch[key];
  }

  return branch;
};

export const setDataByPath = (data, path, value) => {
  if (data === null) data = {};

  let pathArray = path.split(".");
  let key = pathArray.pop();
  let branch = data ? data : {};

  for (let idx in pathArray) {
    const key = typeof pathArray[idx] === "string" ? pathArray[idx] : parseInt(pathArray[idx]);
    if (!branch[key]) {
      branch[key] = typeof pathArray[idx] === "string" ? {} : [];
    }
    branch = branch[key];
  }

  branch[key] = value;

  return data;
};

export const absTarget = (path, target) => {
  if (target && target.includes("$")) return target.replace("$", path.length ? `${path.join(".")}.` : "");
  return target;
};

/*

export const toJSON = (data) => {
  let parsed = {};

  // data = { "name": "Ricardo", "qtdFilhos": "2", "children.0.name": "Cauê", "children.0.food.0.name": "Batata-frita", "children.0.food.1.name": "Chocolate", "children.1.name": "Camila", "children.1.food.0.name": "Sorvete", "deixarMensagem": true, "mensagem": "Minha mensagem" };
  console.log(JSON.stringify(data));

  for (let key in data) {
    const value = data[key];

    const array = key.split(".");
    if (array.length === 1) parsed[key] = value;
    else {
      if (!parsed[array[0]]) parsed[array[0]] = [];

      parsed[array[0]] = deepCreate(
        parsed[array[0]],
        array[1],
        array[2],
        array.splice(3),
        value
      );
      // console.log('parsed[',array[0],'] = deepCreate(parsed[', array[0], '], ', array[1], array[2], array.splice(3), value, ');');
    }
  }

  return parsed;
};

function deepCreate(parsed, idx, property, next, value) {
  if (!parsed[idx]) parsed[idx] = {};

  if (!next.length) parsed[idx][property] = value;
  else {
    if (!parsed[idx][property]) parsed[idx][property] = [];
    parsed[idx][property] = deepCreate(
      parsed[idx][property],
      next[0],
      next[1],
      next.splice(2),
      value
    );
    // console.log('parsed[',idx,'][',property,'] = deepCreate(parsed[',idx,',',property,'], ', next[0], next[1], next.splice(2), value, ');');
  }

  return parsed;
} */
