export const getDatabaseName = (userId, formId) => {
  return `user_${userId}__form_${formId}`;
};

export const getAllDataFromForm = (store, onlyReady = false, notRemoved = true) => {
  return new Promise((resolve, reject) => {
    let retrievedData = [];
    store
      .iterate((value, key) => {
        if ((!onlyReady || value.ready) && (!notRemoved || !value.removedTS)) retrievedData.push({ ...value, key });
      })
      .then(() => {
        resolve(retrievedData);
      })
      .catch((e) => reject(e));
  });
};

/**
 * Get all non ready collects
 *
 * @param {LocalForage} store
 * @returns {Promise<Object[]>}
 *  {key: string, form_field_one: value, form_field_two...}
 */
export async function getAllNonReadyData(store) {
  return new Promise((resolve, reject) => {
    const nonReadyData = [];

    store
      .iterate((value, key) => {
        if (!value.ready) {
          nonReadyData.push({ ...value, key });
        }
      })
      .then(() => resolve(nonReadyData))
      .catch((e) => reject(e));
  });
}

export const removeReady = async (store) => {
  // remove todos os ready
  const itensToRemove = await new Promise((resolve, reject) => {
    let itensToRemove = [];
    store
      .iterate((value, key) => {
        if (value.ready) itensToRemove.push(key);
      })
      .then(() => {
        resolve(itensToRemove);
      })
      .catch((e) => reject(e));
  });

  for (let idx in itensToRemove) {
    console.log(`REMOVING ${itensToRemove[idx]}`);
    await store.removeItem(itensToRemove[idx]);
  }

  // e, se vazia (length) - remove base
  const length = await store.length();
  if (length === 0) {
    await store.dropInstance();
  }
};
