import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useUser } from "./useUser";
import { useLocation, useHistory } from "react-router-dom";

import axios from "axios";

import secret from "../secret";

import logo from "../images/logo.png";

import CryptoJS from "crypto-js";

import logoOrigensBrasil from "../images/logoorigensbrasil.png";
import { Typography } from "@material-ui/core";

import AppForm from "./views/AppForm";

export default () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { search } = useLocation();

  const [, str] = search.split("?");

  let token = str.substring(6, 999);

  const decryptWithAES = (ciphertext) => {
    const passphrase = "origensBrasil@#";
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  useEffect(() => {
    verifyIfExpiredToken();
  }, []);

  const verifyIfExpiredToken = () => {
    const tokenData = decryptWithAES(token);

    if (tokenData === "") {
      alert("token invalido");

      history.push("/");
    }

    const [, expiredDate] = tokenData.split(",");

    const now = Math.floor(Date.now() / 1000);

    if (expiredDate - now < 0) {
      alert("Seu token está expirado, solicite a senha novamente");

      history.push("/esqueci-minha-senha");
    }
  };

  const [loginError, setLoginError] = useState();

  async function handleResetPassword() {
    if (password !== confirmPassword) {
      setLoginError("As senhas não conferem");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER}password-reset`, {
        token: token,
        password: password,
      });

      history.push("/");
    } catch (error) {
      setLoginError(error);
    }
  }

  return (
    <>
      {/* <AppForm> */}
      {/* {process.env.REACT_APP_SITE_NAME === "Avina Collect" && (
          <LogoContainer>
            <img src={logo} />
          </LogoContainer>
        )} */}
      {process.env.REACT_APP_SITE_NAME === "Coletor Origens Brasil" && (
        <LogoContainerOrigens>
          <img src={logoOrigensBrasil} />
        </LogoContainerOrigens>
      )}
      <Typography align="center" variant="h5" color="textPrimary">
        Redefinição de Senha
      </Typography>
      <LoginContainer>
        <form noValidate autoComplete="off">
          <div>
            <TextField
              label="Senha"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <TextField
              label="Repita a senha"
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={handleResetPassword}>
              Redefinir
            </Button>
            <FormHelperText error> {loginError}</FormHelperText>
          </div>
        </form>
      </LoginContainer>
      {/* </AppForm> */}
    </>
  );
};
const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 0px;

  & form {
    margin: 0 auto;
  }

  & div {
    padding: 2px;
  }

  button {
    width: 100%;
  }
`;
const LogoContainer = styled.div`
  text-align: center;
  margin: 70px 0 0 0;
`;
const Title = styled.h1`
  text-align: center;
`;
const LogoContainerOrigens = styled.div`
  text-align: center;
  margin: 70px 0 0 0;

  img {
    width: 150px;
    height: 150px;
  }
`;
