import { lazy } from "react";

export default {
  "inline-text": lazy(() => import("./InlineText")),
  "multiline-text": lazy(() => import("./MultilineText")),
  integer: lazy(() => import("./Integer")),
  decimal: lazy(() => import("./Decimal")),
  cpf: lazy(() => import("./CPF")),
  cep: lazy(() => import("./CEP")),
  rg: lazy(() => import("./RG")),
  phone: lazy(() => import("./Phone")),
  "single-select": lazy(() => import("./SingleSelect")),
  check: lazy(() => import("./Check")),
  note: lazy(() => import("./Note")),
  date: lazy(() => import("./Date")),
  "date-picker": lazy(() => import("./DatePicker")),
  "time-picker": lazy(() => import("./TimePicker")),
  password: lazy(() => import("./Password")),
  /* picture: lazy(() => import("./Picture")), */
  picture: lazy(() => import("./Picture2")),
  geolocation: lazy(() => import("./GeoLocation")),
  "multiple-select": lazy(() => import("./MultipleSelect")),
  "rating-check": lazy(() => import("./RatingCheck")),
  "rating-radio": lazy(() => import("./RatingRadio")),

  "filtered-single-select": lazy(() => import("./SingleSelectFiltered")),
  "filtered-multiple-select": lazy(() => import("./MultipleSelectFiltered")),
};
