import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./auth/login";
import Home from "./home";

import ResetPassword from "./auth/resetPassword";
import ForgotPassword from "./auth/forgotPassword";

import Form from "./form";
import View from "./view";
import ViewDB from "./view/ViewDB";
import { useUser } from "./auth/useUser";
import Layout from "./components/Layout";

const AuthRoute = ({ showBackButton, title, children, redirectIfLogged, ...props }) => {
  const [userData] = useUser();

  return (
    <Route {...props}>
      <Layout showBackButton={showBackButton} title={title}>
        {!userData.logged ? <Redirect to="/login" /> : children}
      </Layout>
    </Route>
  );
};

export default () => {
  return (
    <Switch>
      {/*Refirect only to dev admin
      <Redirect exact from="/" to="/admin" />*/}
      <Route path="/login" redirectIfLogged>
        <Login />
      </Route>
      <Route path="/esqueci-minha-senha" redirectIfLogged>
        <ForgotPassword />
      </Route>
      <Route path="/reset-password/">
        <ResetPassword />
      </Route>
      <AuthRoute path="/" exact title="Meus Formulários">
        <Home />
      </AuthRoute>
      <AuthRoute path="/coletar/:id/:regId?" showBackButton>
        <Form />
      </AuthRoute>
      <AuthRoute path="/ver/:id" showBackButton>
        <View />
      </AuthRoute>
      <AuthRoute path="/db" title="Dados Salvos">
        <ViewDB />
      </AuthRoute>
      )
    </Switch>
  );
};
