import { lazy } from "react";

import { getDataByPath, absTarget } from "../utils";

const Strategies = {
  none: ({ path, field }) => {
    if (!path) path = [];

    let mergedPath = path;
    if (field) mergedPath = [...path, field];

    return { items: [{ path: mergedPath }] };
  },

  free: ({ path, field, count }) => {
    if (!path) path = [];

    let items = [];
    for (let idx = 0; idx < count; idx++) {
      let mergedPath = [...path, field, idx];
      items.push({ path: mergedPath });
    }

    return { items };
  },

  dependent: ({ state, strategy: { target }, path, field }) => {
    const data = getDataByPath(state, absTarget(path, target));

    if (!data || isNaN(data) || data <= 0) return { items: [] };

    if (!path) path = [];

    let items = [];
    for (let idx = 0; idx < data; idx++) {
      let mergedPath = [...path, field, idx];
      items.push({ path: mergedPath });
    }

    return { items };
  },

  dependent_multiple: ({ state, strategy: { target }, path, field, parent }) => {
    const data = getDataByPath(state, absTarget(path, target));

    if (!parent || !data || !Array.isArray(data)) return { items: [] };

    const targetForm = parent.find(({ field }) => field === absTarget(path, target));

    if(!targetForm || !targetForm.options) return { items: [] };

    const options = targetForm.options;

    if (!path) path = [];

    let items = [];
    for (let idx = 0; idx < data.length; idx++) {
      const reference = options.find(({ value }) => value=== data[idx]).text;

      let mergedPath = [...path, field, idx];
      items.push({ path: mergedPath, reference });
    }

    // console.log(items);

    return { items };
  },

  getIterative: (type) => {
    return ["free"].includes(type);
  },
};

export default Strategies;

export const Iterator = lazy(() => import("./Iterator"));
