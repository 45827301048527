import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";

import localforage from "localforage";
import axios from "axios";

import { getDatabaseName, getAllNonReadyData } from "../../utils/helpers";
import { useUser } from "../../auth/useUser";

export default ({ isOnline, onSent }) => {
  const [sending, setSending] = useState(false);
  const [sendingMessage, setSendingMessage] = useState("");
  const [{ forms, ...userData }] = useUser();

  // Quais são os possíveis estados que esse componente pode exibir ao usuário?
  // - X formulários encontrados, enviado-os ao servidor...
  // - Nenhuma coleta com envio pendente
  // - Nenhum formulário cadastrado, portanto não há envios pendentes (exibir um status positivo de tudo sincronizado?)
  // - Falha no envio das coletas

  const handleSend = async () => {
    setSending(true);

    try {
      for (let idx in forms) {
        const form = forms[idx];
        const databaseName = getDatabaseName(userData.user.id, form.id);

        const store = localforage.createInstance({
          name: databaseName,
        });

        const length = await store.length();

        if (length > 0) {
          setSendingMessage(`Enviando ${form.title}... `);

          // @TODO talvez tenha um problema de uso de memória, já que aqui reune dados de todos os formulários,
          // e as imagens em base64 são todas carregadas na memória nesse momento pré-envio

          // Somente os itens com ready === false
          const nonReadyData = await getAllNonReadyData(store);

          // Send to API
          const { status } = await axios.post(`${process.env.REACT_APP_SERVER}collect-service/emergency-save`, {
            formId: form.id,
            jsonForm: form,
            appVersionGitHash: process.env.REACT_APP_GIT_SHA,
            data: nonReadyData,
          });

          if (status === 200) {
            for (let item of nonReadyData) {
              await store.removeItem(item.key);
            }

            onSent();
          }
        }
      }

      setSendingMessage("Dados enviados!");
    } catch (ignore) {
      setSendingMessage("Falha no envio, verifique sua conexão.");
    } finally {
      setTimeout(() => {
        setSending(false);
        setSendingMessage("");
      }, 3000);
    }
  };

  return (
    <>
      <ListItem button onClick={handleSend} disabled={!isOnline || sending || !forms}>
        <ListItemIcon>
          <WarningIcon color="error" />
        </ListItemIcon>
        <ListItemText primary={sendingMessage || "Enviar Coletas Incompletas"} />
      </ListItem>
    </>
  );
};
