import React, { useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import axios from "axios";

export default ({ setTitle }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER}collect-service/db`); // id just for offline caching
    setData(response.data);
    //return response.data;
  };

  return (
    <>
      <ReportContainer>
        <Box my={4}>{data.length === 0 && <Typography align="center">Nenhum registro encontrado!</Typography>}</Box>

        {data.length > 0 && (
          <div>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </ReportContainer>
    </>
  );
};

const ReportContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
