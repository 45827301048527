import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import {
  List,
  ListItem,
  ListItemIcon,
  Typography,
  ListItemText,
  Divider,
  Box,
  SwipeableDrawer,
  Switch,
} from "@material-ui/core";

import moment from 'moment';

// import uncaught from 'uncaught';

import RefreshIcon from "@material-ui/icons/Refresh";
import HomeIcon from "@material-ui/icons/Home";
import AccountIcon from "@material-ui/icons/AccountCircle";
import DataUsage from "@material-ui/icons/Storage";
import DnsIcon from '@material-ui/icons/Dns';
import ErrorIcon from '@material-ui/icons/Error';

import { useUser } from "../../auth/useUser";
import { useNetworkStatus } from "../../utils/useNetworkStatus";

import SendDataButton from "./SendData";
import EmergencySendData from "./EmergencySendData";
import EmergencySendDataAll from "./EmergencySendDataAll";

import { version } from '../../config';

export default ({ setOpen, open }) => {
  const history = useHistory();

  const [{ forms, ...userData }, { logout, fetchForms }] = useUser();
  const isOnline = useNetworkStatus();

  const [testing, _testing] = useState(false);
  const [testingMessage, _testingMessage] = useState(null);

  const [errorConsole, _errorConsole] = useState('');
  const [showConsole, _showConsole] = useState(false);

  const [debug, _debug] = useState(false);

  /* useEffect(() => {
    uncaught.start();
    uncaught.addListener((error) => {
      console.log('Uncaught error or rejection: ', error, error.message);
    });

    return () => {
      uncaught.removeAllListeners();
      uncaught.stop();
    }
  }, []) */

  const onSent = () => {
    history.push("/");
  };

  const onError = (data) => {
    console.log('>>>>>>>>ERROR', data);
    _errorConsole(`${moment().format('HH:mm:ss')}\n${JSON.stringify(data)}\n${data}${errorConsole.length ? '\n\n' : ''}${errorConsole}`)
  }

  const handleTest = async () => {
    _testing(true);
    _testingMessage('Testando...')

    try {
      const { data: { ok, server_timestamp } } = await axios.get(`${process.env.REACT_APP_SERVER}general-service/test`);

      _testingMessage(ok ? 'Teste concluido com sucesso!' : 'Problemas com o teste!');
    } catch (err) {
      _testingMessage(`Problemas com o teste! - ERR: ${err.message}`);
      throw err;
    } finally {
      _testing(false);
    }

    setTimeout(() => {
      _testingMessage(null);
    }, 3000)
  }

  return (
    <SwipeableDrawer anchor="right" open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <Box>
        <List>
          <ListItem
            button
            onClick={() => {
              history.push("/");
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Meus Formulários" />
          </ListItem>

          <Divider />

          <ListItem button onClick={fetchForms} disabled={!isOnline}>
            <ListItemIcon>
              <RefreshIcon />
            </ListItemIcon>
            <ListItemText primary="Atualizar Formulários" />
          </ListItem>

          <SendDataButton forms={forms} userData={userData} isOnline={isOnline} onSent={onSent} onError={onError} />

          {userData.logged && userData.user.isAdmin && (
            <ListItem
              button
              onClick={() => {
                history.push("/db");
                setOpen(false);
              }}
              disabled={!isOnline}
            >
              <ListItemIcon>
                <DataUsage />
              </ListItemIcon>
              <ListItemText primary="Banco de Dados" />
            </ListItem>
          )}

          <Divider />

          <ListItem>
            <ListItemText primary={`Versão: ${version}`} />
            <Switch size="small" checked={debug} onChange={() => _debug(!debug)} />
          </ListItem>
        </List>

        {!isOnline && <Typography variant="subtitle1">Usando Offline </Typography>}

        {debug && <List style={{ marginTop: '80px' }}>

          <Divider />

          <EmergencySendData forms={forms} userData={userData} isOnline={isOnline} onSent={onSent} />

          <EmergencySendDataAll forms={forms} userData={userData} isOnline={isOnline} onSent={onSent} />

          <ListItem button onClick={handleTest} disabled={testing}>
            <ListItemIcon>
              <DnsIcon />
            </ListItemIcon>
            <ListItemText primary={testingMessage ? testingMessage : 'Testar conexão com servidor'} />
          </ListItem>

          <ListItem button onClick={() => _showConsole(!showConsole)}>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            <ListItemText primary={showConsole ? 'Fechar console de erros' : 'Abrir console de erros'} />
          </ListItem>

          {showConsole && <ListItem>
            <textarea style={{ height: '20vh', width: '100%' }} value={errorConsole} onChange={(e) => _errorConsole(e.target.value)} />
          </ListItem>}
          <Divider />
        </List>}

        <List style={{ position: "absolute", bottom: "0px" }}>
          <ListItem
            button
            onClick={() =>
              logout(() => {
                history.push("/");
              })
            }
          >
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};
