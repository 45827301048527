import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useUser } from "./useUser";
import { useHistory } from "react-router-dom";

import logoOrigensBrasil from '../images/logoorigensbrasil.png'
import { Typography } from "@material-ui/core";

import AppForm from "./views/AppForm";

import axios from 'axios'

import secret from '../secret'

import logo from "../images/logo.png";

import CryptoJS from 'crypto-js'

export default () => {

    const history = useHistory();
    const [email, setEmail] = useState('')
  

  const [loginError, setLoginError] = useState();

  async function handleForgotPassword() {
    if(!email) {
        setLoginError("Digite seu e-mail")
        return
    }

    // TODO
    // validar o formato do email

    try {
        await axios.post(`${process.env.REACT_APP_SERVER}password/forgot`, {
            email: email,
            origin: 'coletor'
        })

        alert('Verifique seu e-mail para continuar com a redefinição de senha')

        history.push('/')

    } catch (error) {
        setLoginError(error)
    }
  }

  return (
    <>
      {/* <AppForm> */}
        {/* {process.env.REACT_APP_SITE_NAME === "Avina Collect" && (
        <LogoContainer>
        <img src={logo} />
        </LogoContainer>
        )} */}
        {process.env.REACT_APP_SITE_NAME === "Coletor Origens Brasil" && (
        <LogoContainerOrigens>
            <img src={logoOrigensBrasil} />
        </LogoContainerOrigens>
        )}
        <Typography align="center" variant="h5" color="textPrimary">
          Esqueceu sua senha?
        </Typography>
        <LoginContainer>
            <form noValidate autoComplete="off">
                <div>
                    <TextField 
                        label="E-mail" 
                        variant="outlined" 
                        type="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleForgotPassword}
                >
                    Enviar link
                </Button>
                <FormHelperText error> {loginError}</FormHelperText>
                </div>
            </form>
        </LoginContainer>
      {/* </AppForm> */}
    </>
  );
};
const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0px 20px 0px;
  padding-bottom: 15px;

  & form {
    margin: 0 auto;
  }

  & div {
    padding: 2px;
  }

  button {
    width: 100%;
  }
`;
const LogoContainer = styled.div`
  text-align: center;
  margin: 70px 0 0 0;
`;
const Title = styled.h1`
    text-align: center;
`;

const LogoContainerOrigens = styled.div`
  text-align: center;
  margin: 70px 0 0 0;

  img {
    width: 150px;
    height: 150px;
  }
`;
