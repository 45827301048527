import React, { Suspense, useState, useContext, useEffect } from "react";

import { FieldsContext, DataContext } from "./contexts";

import Sector from "./Sector";

import styled from "styled-components";

import iterations, { Iterator } from "./iterations";

import availability from "./availability";

import classnames from "classnames";

import getValue from 'get-value';

function Block({ type, form, path, page, update, parent, onEnabledCam,  enabledCam }) {
  /* useEffect(()=>{
        console.log('in BLOCK', update);
    }, [update]) */

  const state = useContext(DataContext);
  const doChange = useContext(FieldsContext);

  let IterationStrategy = iterations["none"];
  let iterative = false;
  if (form.iteration && iterations[form.iteration.type]) {
    IterationStrategy = iterations[form.iteration.type];
    iterative = iterations.getIterative(form.iteration.type);
  }

  let total = 1;
  let totalField;
  if (iterative) {
    totalField = [...path, `__${form.field}`].join(".");
    
    const stf = getValue(state, totalField);

    if (state && stf) total = stf;
  }

  const [count, setCount] = useState(total); // for free iterations

  const { items: iterationItems } = IterationStrategy({
    field: form.field,
    path,
    count,
    strategy: form.iteration,
    state,
    parent,
  });

  let isAvaliable = true;
  if (form.available) {
    let aType = form.available.type ? form.available.type : "and";
    if (availability[aType]) {
      /* console.log(path); */
      isAvaliable = availability[aType](form.available.conditions, state, path);
    }
  }

  useEffect(() => {
    if (totalField) doChange(totalField, count);
  }, [count]);

  return (
    <>
      {isAvaliable && (
        <BlockContainer className={classnames({ "page-block": type === "page" })}>
          {!!form.title && type !== "page" && <h4>{form.title}</h4>}
          <Suspense fallback={<div>Carregando...</div>}>
            <Iterator items={iterationItems} iterative={iterative} count={count} setCount={setCount} isPage={type === "page"}>
              <Sector update={update} page={page} content={form.children} onEnabledCam={onEnabledCam} enabledCam={enabledCam}/>
            </Iterator>
          </Suspense>
        </BlockContainer>
      )}
    </>
  );
}

const BlockContainer = styled.div`
  :not(.page-block) {
    border: dashed 1px #bbb;
    margin: 10px 0;
    padding: 5px;
    border-radius: 1px;
  }
  h4 {
    margin-top: 5px;
  }
`;

export default Block;
