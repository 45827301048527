import React, { Fragment, useEffect, useState } from "react";

import { useRouteMatch, useHistory } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import localforage from "localforage";

import Fab from "@material-ui/core/Fab";
import DoneIcon from "@material-ui/icons/Done";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { deepFind, getDeepKeys, setToValue } from "../utils/objectFunctions";

import classnames from "classnames";

import { getDatabaseName } from "../utils/helpers";
import { useUser } from "../auth/useUser";
import FormBuilder from "./builder";

import logoOrigensBrasil from "../images/logoorigensbrasil.png";

import analyseTree from "./Analyser";

let timer = null;

export default ({ setTitle }) => {
  let match = useRouteMatch();
  let [{ forms, ...userData }, { fetchForms }] = useUser();

  const history = useHistory();

  const { id, regId } = match.params;

  const [form, setForm] = useState(null);
  const [data, setData] = useState(null);
  const [invalid, setInvalid] = useState([]);
  const [invalidPages, setInvalidPages] = useState([]);

  const [updated, setUpdated] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);

  const [saving, setSaving] = useState(null);
  const [saveStrategy, setSaveStrategy] = useState({ type: "button" });

  const [validated, _validated] = useState(null);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
  };

  // TODO: remover- utilizar estratégia em useUser();
  const refetchForms = async () => {
    forms = await fetchForms();
  };
  if (!forms) refetchForms();

  useEffect(() => {
    if (!forms) return;

    const [selectedForm] = forms.filter((form) => form.id === id);

    if (selectedForm) {
      setForm(selectedForm);
      setTitle(selectedForm.title);
      setSaveStrategy(selectedForm.saveStrategy);
    }

    if (regId) {
      getData();
    }

    // eslint-disable-next-line
  }, [forms, id, regId]);

  const getData = async () => {
    // recupera os dados de uma coleta e coloca em data
    const databaseName = getDatabaseName(userData.user.id, id);

    const store = localforage.createInstance({
      name: databaseName,
    });

    const value = await store.getItem(regId);

    setData((data) => ({ ...data, ...value }));
  };

  const handleValidate = async () => {
    const validationOk = await saveData(true); // save data if verified
    notifyValidation(validationOk);
  };

  const handleSave = async () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    saveData(false); // save data inconditionally
  };

  const saveData = async (verify) => {
    const { cleanData, validation } = analyseTree(form.content, data);

    if (verify) {
      setInvalid({ ...validation.errors });
      setInvalidPages(validation.invalidPages);
    }

    if (verify && !validation.valid) return false;

    setSaving(true);

    const databaseName = getDatabaseName(userData.user.id, id);

    const key = regId ? regId : uuidv4();

    const store = localforage.createInstance({
      name: databaseName,
    });

    try {
      // new?
      if (!regId || !data.createdAt) cleanData.createdAt = new Date();
      else cleanData.createdAt = data.createdAt;

      cleanData.updatedAt = new Date();

      cleanData.ready = verify;

      const value = await store.setItem(key, cleanData);

      setSaving(false);

      // next, if new
      if (!regId) {
        if (verify) setData({});
        else history.replace(`/coletar/${id}/${key}`);
      }
    } catch (e) {
      console.log(e);
      return false;
    }

    setOpenNotification(true);

    setUpdated(false);

    return true;
  };

  const handleChange = (changedData) => {
    //TODO
    // validar se tem o data.removedTS
    // se tiver -> não fazer o handleChange

    // inicialmente o data é null

    if (data !== null) {
      if (data.removedTS) return;
    }

    setData((data) => ({ ...data, ...changedData }));
    setUpdated(true);

    if (saveStrategy.type === "when-idle") {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      let delay = 1000;
      if (saveStrategy.delay && !isNaN(saveStrategy.delay)) delay = saveStrategy.delay;

      timer = setTimeout(handleSave, delay);
    }
  };

  const handlePageChange = () => {
    if (saveStrategy.type === "per-page" && updated) {
      handleSave();
    }
  };

  const notifyValidation = (validated) => {
    _validated(validated);
    setTimeout(() => _validated(null), 3000);
  };

  /* const handleBlur = () => {
    // TODO: teste de validação em onBlur - este teste está restrito a inlineText, por enquanto
    console.log('BLUR');
    const { validation } = analyseTree(form.content, data);

    if(!validation.valid) {
      setInvalid({ ...validation.errors });
      setInvalidPages(validation.invalidPages);
    }
  } */

  return (
    <>
      <img src={logoOrigensBrasil} style={{ width: 100, height: 100, display: "block", margin: "0 auto" }} alt="" />

      {form && (data !== null || !regId) && (
        <FormBuilder
          form={form}
          onChange={handleChange}
          data={data}
          invalid={invalid}
          invalidPages={invalidPages}
          saving={saving}
          updated={updated}
          onPageChange={handlePageChange}
          /* handleBlur={handleBlur} */
        />
      )}

      {validated !== null && (
        <Snackbar
          open
          message={validated ? "Formulario validado!" : "Problemas foram detectados!"}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className={classnames("snack", { success: validated, error: !validated })}
        />
      )}

      {data !== null && !data.removedTS && (
        <>
          <Fab
            color="primary"
            onClick={handleSave}
            size="large"
            style={{ position: "fixed", bottom: 25, right: 90 }}
            disabled={!updated}
          >
            <SaveIcon />
          </Fab>

          <Fab
            data-testid="validateForm"
            color="secondary"
            onClick={handleValidate}
            size="large"
            style={{ position: "fixed", bottom: 25, right: 15 }}
          >
            <DoneIcon />
          </Fab>
        </>
      )}

      {/* <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={openNotification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        message="Registro gravado"
        action={
          <Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseNotification}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      /> */}
    </>
  );
};
