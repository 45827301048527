import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Drawer from "./Drawer";

const Layout = ({ showBackButton, title: initialTitle, children }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  useEffect(() => {
    if (initialTitle) {
      setTitle(initialTitle);
    }
  }, [initialTitle]);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {showBackButton && (
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
            {title && <Typography variant="h6">{title}</Typography>}
          </div>

          <IconButton edge="end" onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer setOpen={setOpen} open={open} />
      {React.cloneElement(children, { setTitle })}
    </>
  );
};

export default Layout;
