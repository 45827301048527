import React, { Suspense, useContext, useState } from "react";

import { FieldsContext, DataContext, InvalidContext, ListsContext } from "./contexts";

import styled from "styled-components";

import fields from "./fields";

import { getDataByPath } from "./utils";

import Box from "@material-ui/core/Box";

import availability from "./availability";

import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import HelpOutline from "@material-ui/icons/HelpOutline";

function TriggersTooltips(hint) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={hint}
      >
        <IconButton onClick={handleTooltipOpen} size="small">
          <HelpOutline style={{ width: 16, height: 16 }} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

function Field({ form, path, page, update, onEnabledCam, enabledCam }) {
  const state = useContext(DataContext);
  const doChange = useContext(FieldsContext);
  const invalid = useContext(InvalidContext);
  const lists = useContext(ListsContext);

  const handleChange = (path, value) => {
    doChange(path, value);
  };

  let Field;
  if (fields[form.type]) Field = fields[form.type];
  else Field = fields["inline-text"];

  let name = form.field;
  if (path && path.length) name = `${path.join(".")}.${form.field}`;

  const isInvalid = Object.keys(invalid).includes(name);

  let isAvaliable = true;
  if (form.available) {
    let aType = form.available.type ? form.available.type : "and";
    if (availability[aType]) {
      isAvaliable = availability[aType](form.available.conditions, state, path);
    }
  }

  let label = null;
  try {
    if (path && path.length > 1 && !isNaN(path[path.length - 1])) label = path[path.length - 1] + 1;
  } catch (_) {}

  return (
    <>
      {isAvaliable && (
        <Box mx={2}>
          {!!form.title && (
            <StyledLabel className={isInvalid ? "invalid" : ""}>
              {/* {label && <>#{label} - </>} */}
              {form.title} {form.hint && TriggersTooltips(form.hint)}
            </StyledLabel>
          )}
          <Suspense fallback={<div>Carregando...</div>}>
            <Field
              name={name}
              form={form}
              onChange={handleChange}
              value={getDataByPath(state, name)}
              invalid={isInvalid}
              errors={isInvalid ? invalid[name] : []}
              lists={lists}
              state={state}
              update={update}
              onEnabledCam={onEnabledCam}
              enabledCam={enabledCam}
              /* onBlur={doBlur} */
            />
          </Suspense>
        </Box>
      )}
    </>
  );
}

const StyledLabel = styled.h4`
  margin-bottom: 5px;
  font-size: 16px;

  &.invalid {
    color: red;
  }
`;

export default Field;
