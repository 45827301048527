import React, { Fragment, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import localforage from "localforage";
import TableContainer from "@material-ui/core/TableContainer";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Error from "@material-ui/icons/Error";
import CheckCircle from "@material-ui/icons/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Snackbar from "@material-ui/core/Snackbar";

import styled from "styled-components";

import { useUser } from "../auth/useUser";
import { Typography } from "@material-ui/core";

import { getDatabaseName } from "../utils/helpers";

import moment from "moment";

import classnames from "classnames";

import { deepFind, getDeepKeys } from "../utils/objectFunctions";
import { mobileDevice } from "../utils/device";

const getData = async (id, userData, removed = false) => {
  const databaseName = getDatabaseName(userData.user.id, id);

  const store = localforage.createInstance({
    name: databaseName,
  });

  try {
    return await new Promise((resolve) => {
      let retrievedData = [];
      store
        .iterate((value, key) => {
          // filtro para inserir apenas os que não foram enviados

          retrievedData.push({ ...value, key });
        })
        .then(() => {
          resolve(retrievedData);
        });
    });
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default ({ setTitle }) => {
  const [form, setForm] = useState(null);
  const [data, setData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reg2Delete, setReg2Delete] = useState(null);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  let [{ forms, ...userData }, { fetchForms }] = useUser();
  let match = useRouteMatch();

  const history = useHistory();

  // TODO: remover- utilizar estratégia em useUser();
  const refetchForms = async () => {
    forms = await fetchForms();
  };
  if (!forms) refetchForms();

  const { id } = match.params;

  

  useEffect(() => {
    if (!forms) return;

    async function fetchData() {
      setData(await getData(id, userData));
    }

    const search = forms.filter((form) => form.id === id);

    if (search.length) {
      setForm(search[0]);
      fetchData();
    }
  }, [forms, id, userData.user.id]);

  useEffect(() => {
    if (form) {
      setTitle(form.title);
    }
  }, [form, setTitle]);

  let fields2Show = [["Coletado em", "createdAt"]];
  if (form && form.highlight) {
    fields2Show = [...form.highlight, ...fields2Show];
  }

  const handleEdit = (regId) => {
    history.push(`/coletar/${id}/${regId}`);
  };

  const handleNew = () => {
    history.push(`/coletar/${id}`);
  };

  const handleDelete = async () => {
    handleCloseDeleteDialog();

    await doDelete(reg2Delete);

    setNotificationText("Registro removido");
    setOpenNotification(true);

    setData(await getData(id, userData));

    setReg2Delete(null);
  };

  const handleClickOpenDeleteDialog = (regId) => {
    setReg2Delete(regId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const doDelete = async () => {
    const databaseName = getDatabaseName(userData.user.id, id);

    const store = localforage.createInstance({
      name: databaseName,
    });

    const value = await store.getItem(reg2Delete);

    await store.setItem(reg2Delete, { ...value, removedTS: Date.now() });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
  };


  function getNameField(content) {
    let content_keys = getDeepKeys(content);
    let name_field = "";
    content_keys.forEach((content_key) => {
      let last_key = content_key.split(".").reverse()[0];
      if (last_key === form["defaultField"]) {
        name_field = deepFind(content, content_key);
      }
    });
    if (name_field === "Vazio") {
      return "";
    }
    return name_field;
  }

  return (
    <>
      <ReportContainer>
        <Box my={4}>
          {data.length === 0 && <Typography align="center">Nenhum registro pendente para envio!</Typography>}
        </Box>

        {data.length > 0 && (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" style={{ marginBottom: 20 }}>
                <TableHead>
                  <TableRow>
                    {fields2Show.map(([title, field]) => (
                      <TableCell key={field} align="left">
                        {title}
                      </TableCell>
                    ))}

                    <TableCell align="left">Nome</TableCell>

                    <TableCell align="left">Opções</TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data &&
                    sortData(data)
                      .filter((i) => !i.removedTS)
                      .map((row) => (
                        <StyledTableRow key={row.key} className={classnames({ "not-ready": !row.ready })}>
                          {fields2Show.map(([_, field], idx) => (
                            <TableCell key={field} component="th" scope="row">
                              {field === "createdAt"
                                ? moment(row[field]).format("DD/MM/YYYY HH:mm")
                                : typeof row[field] === "object"
                                ? JSON.stringify(row[field])
                                : row[field]}
                            </TableCell>
                          ))}
                          <TableCell>{getNameField(row)}</TableCell>
                          <TableCell component="th" scope="row">
                            <IconButton aria-label="edit" size="small" onClick={() => handleEdit(row.key)}>
                              <Tooltip title="Editar">
                                <EditIcon fontSize="inherit" />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => handleClickOpenDeleteDialog(row.key)}
                            >
                              <Tooltip title="Excluir">
                                <DeleteIcon fontSize="inherit" />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {!row.ready ? (
                              <>
                                {mobileDevice() 
                                  ? 
                                    <Typography variant="caption">Não validado</Typography> 
                                  : (
                                    <Tooltip title="Não validado">
                                      <Error fontSize="inherit" />
                                    </Tooltip>
                                  )
                                }
                              </>
                            ) : (
                              <>
                                {mobileDevice() 
                                  ? 
                                    <Typography variant="caption">Validado</Typography> 
                                  : (
                                    <Tooltip title="Validado">
                                      <CheckCircle fontSize="inherit" />
                                    </Tooltip>
                                  )
                                }
                              </>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography style={{ color: "#57b529" }}>Coletas Enviadas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {fields2Show.map(([title, field]) => (
                          <TableCell key={field} align="left">
                            {title}
                          </TableCell>
                        ))}

                        <TableCell align="left">Nome</TableCell>

                        <TableCell align="left" />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data &&
                        sortData(data)
                          .filter((i) => i.removedTS)
                          .map((row) => (
                            <StyledTableRow key={row.key} className={classnames({ "not-ready": !row.ready })}>
                              {fields2Show.map(([_, field], idx) => (
                                <TableCell key={field} component="th" scope="row">
                                  {field === "createdAt"
                                    ? moment(row[field]).format("DD/MM/YYYY HH:mm")
                                    : typeof row[field] === "object"
                                    ? JSON.stringify(row[field])
                                    : row[field]}
                                </TableCell>
                              ))}
                              <TableCell>{getNameField(row)}</TableCell>

                              <TableCell>
                                <Tooltip title="Ver coleta">
                                  <VisibilityIcon fontSize="inherit" onClick={() => handleEdit(row.key)} />
                                </Tooltip>
                              </TableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </TableContainer>
          </>
        )}
      </ReportContainer>

      <Fab color="secondary" onClick={handleNew} size="large" style={{ position: "fixed", bottom: 25, right: 15 }}>
        <AddIcon />
      </Fab>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Você deseja remover este registro?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Todos os dados desta coleta serão perdidos!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={openNotification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        message={notificationText}
        action={
          <Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseNotification}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      />
    </>
  );
};

function sortData(data) {
  return data.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    return 1;
  });
}

const ReportContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const StyledTableRow = styled(TableRow)`
  &.not-ready .MuiTableCell-body {
    color: #999 !important;
  }
  &:not(.not-ready) .MuiTableCell-body {
    color: #57b529 !important;
    font-weight: bold;
  }
`;
